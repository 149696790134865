import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import SimpleMap from './SimpleMap';
import { FaArrowCircleLeft, FaArrowLeft, FaCheck, FaCheckCircle, FaCross, FaTimes, FaTrash } from 'react-icons/fa';
import { userInfo } from './UserInfo';
import { put,deleteRecord } from './Api';
import SweetAlert from 'react-bootstrap-sweetalert';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';

export default function DetailForm(props) {
  const [status, setStatus] = useState(props.record.status);
  const [note, setNote] = useState("");
  const [isDelete, setDelete] = useState(false);
  const [licenceNumber, setLicenceNumber] = useState(props.record.licenceNumber);
  const [vehicleNumber, setVehicleNumber] = useState(props.record.vehicleNumber);
  const [phone, setPhone] = useState(props.record.phone);
  const [name, setName] = useState(props.record.name);

  const onchangeStatus=(evt)=>{
    setStatus(evt.target.value)
  }

  useEffect(() => {
    // Update the document title using the browser API
    setStatus(props.record.status)
    setLicenceNumber(props.record.licenceNumber);
    setVehicleNumber(props.record.vehicleNumber)
    setPhone(props.record.phone)
  },[props.record.id]);


  const saveData=()=>{

    const record = Object.assign({},props.record);
    record.notes=record.notes||[];
    record.notes.unshift({
      oldStatus: record.status,
      status: status,
      message: note,
      time: new Date().getTime(),
      updateBy: userInfo.firstName,
      updateByUser: userInfo.username
    })
    record.status=status;
    record.licenceNumber=licenceNumber;
    record.vehicleNumber=vehicleNumber;
    const partnerId=userInfo.partnerId||"zrider";
    put("/objects/"+partnerId+"_enquiries/"+record.id+"?partnerId="+partnerId,record).then(resp=>{
      props.refresh();
    })
  }

  const deleteConfirm=()=>{
    setDelete(true);
  }
  const deleteData=()=>{


    const partnerId=userInfo.partnerId||"zrider";
    deleteRecord("/objects/"+partnerId+"_enquiries/"+props.record.id+"?partnerId="+partnerId,null).then(resp=>{
      props.refresh();
    })
  }
  return (
    <div className="text-xs" style={{ display: 'block', 
                  width: "100%",
                  color:"#eee",
                  
                  height:(window.innerHeight-74)+"px",
                  padding: 10 }}>

{
        isDelete  && 
        <SweetAlert style={{color:"black"}}
	warning
	showCancel
	confirmBtnText="Yes, Proceed!"
	confirmBtnBsStyle="danger"
	title="Data will get deleted permanantly. Are you sure?"
	onConfirm={()=>{setDelete(false);deleteData()}}
	onCancel={()=>{setDelete(false)}}
	focusCancelBtn
  >
	
  </SweetAlert>
      }
      <Form style={{paddingBottom:"20px"}}>
      <Form.Group style={{height:"60px"}}>
        {/* <Button  variant="info" style={{float:"left"}} onClick={()=>props.onSelectRecord(null)}>
          <FaArrowLeft></FaArrowLeft>  Close
        </Button>
        <Button  variant="danger" style={{marginLeft:"30px",float:"left"}} type="button"  onClick={()=>deleteConfirm()}>
            <FaTimes></FaTimes> Delete
        </Button>
        <Button  variant="primary" type="button" style={{float:"right"}} onClick={()=>saveData()}>
            <FaCheck></FaCheck> Save
        </Button> */}
<div className="flex flex-row gap-2">
<Button   variant="info"  onClick={()=>props.onSelectRecord(null)}>
         <span className="flex items-center gap-2" ><FaArrowCircleLeft></FaArrowCircleLeft> Close</span> 
        </Button>
        <Button variant="danger"  type="button"  onClick={()=>deleteConfirm()}>
           
            <span className="flex items-center gap-2" > <FaTrash></FaTrash> Delete</span> 
        </Button>
        <Button  variant="primary" type="button"  onClick={()=>saveData()}>
        <span className="flex items-center gap-2" > <FaCheckCircle></FaCheckCircle> Save</span> 

            
        </Button>
  
      </div>
      </Form.Group>
      <Form.Group>
          <Form.Label>Status:</Form.Label>
          <Form.Control as="select" value={status} onChange={onchangeStatus}>
        <option value="Pending">Pending</option>
        <option value="Suspended">Suspended</option>
        <option value="Approved">Approved</option>
        <option value="Rejected">Rejected</option>
        <option value="Terminated">Terminated</option>
        
     </Form.Control>
        </Form.Group>

        <Form.Group>
          <Form.Label>Phone:</Form.Label>
          <Form.Control  value={phone} onChange={e=>setPhone(e.target.value)}/>
        
        
        </Form.Group>


        <Form.Group>
          <Form.Label>Driver Name:</Form.Label>
          <Form.Control  value={name} onChange={e=>setName(e.target.value)}/>
        
        
        </Form.Group>

        <Form.Group>
          <Form.Label>Licence Number:</Form.Label>
          <Form.Control  value={licenceNumber} onChange={e=>setLicenceNumber(e.target.value)}/>
        
        
        </Form.Group>
        <Form.Group>
          <Form.Label>Vehicle Number:</Form.Label>
          <Form.Control  value={vehicleNumber} onChange={e=>setVehicleNumber(e.target.value)}/>
        
        
        </Form.Group>

      


        <Form.Group>
        
<div className='flex gap-2'>
  <div>
  <Form.Label>Vehicle Image</Form.Label>
<img className='rounded-lg shadow-lg' style={{height:"200px",width:"200px",objectFit:"cover"}} src={props.record.vehicleImageURL}></img>
</div>
<div>
  <Form.Label>Driver Image</Form.Label>
<img className='rounded-lg shadow-lg' style={{height:"200px",width:"200px",objectFit:"cover"}}  src={props.record.driverImageURL}></img>
</div>
</div>
</Form.Group>

<Form.Group>
          <Form.Label>Note</Form.Label>
         
          <Form.Control as="textarea" rows="3" onChange={e=>setNote(e.target.value)} />
        </Form.Group>

        
        {/* <Form.Group>
          <Form.Label>Location</Form.Label>
          <div>
            <SimpleMap record={props.record}/>
          </div>
        </Form.Group> */}
        
      </Form>
    </div>
  );
}