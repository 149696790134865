import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
  } from '@chakra-ui/react'
import { useEffect, useState } from 'react';
  import Button from 'react-bootstrap/Button';

import { FaPassport } from 'react-icons/fa'
import { get } from './Api';
import { Form } from 'react-bootstrap';


  export function ShowPassword({accountId,partnerId}) {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [data,setData] = useState({});
    useEffect(()=>{
        if(isOpen){
            get("/accounts/"+accountId+"?partnerId="+partnerId,null).then(resp=>{
                setData(resp.rows[0].data);
                console.log(resp)
            })
        }
    },[accountId,isOpen])
    return (
      <>
        <Button onClick={onOpen} variant='success'>
        <span className="flex items-center gap-2" ><FaPassport></FaPassport>Show Password</span> 

            </Button>
  
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Account Details</ModalHeader>
            <ModalCloseButton />
            <ModalBody>

            <Form>

            <Form.Group>
          <Form.Label>Username:</Form.Label>
          <Form.Control disabled value={data.username} />
        
        
        </Form.Group>
        <Form.Group>
          <Form.Label>Password:</Form.Label>
          <Form.Control disabled  value={data.password} />
        
        
        </Form.Group>
            </Form>
                {

                }
              
            </ModalBody>
  
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }