import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import SimpleMap from './SimpleMap';
import { FaArrowCircleLeft, FaArrowLeft, FaCheck, FaCheckCircle, FaCross, FaTimes, FaTrash, FaTrashAlt } from 'react-icons/fa';
import { userInfo } from './UserInfo';
import { put, deleteRecord, get } from './Api';
import SweetAlert from 'react-bootstrap-sweetalert';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import LocationInput from './maps/LocationInput';
import { AnimatePresence, motion } from "framer-motion"
import { ShowPassword } from './ShowPassword';


export default function OneDRideConnectForm(props) {

  const [record, setRecord] = useState(null)
  const [isDelete, setDelete] = useState(false);



  const onchangeStatus = (evt) => {
  }

  useEffect(() => {

    get("/objects/" + props.record.partnerId + "_pa/zrider_instance_" + props.record.to + "?partnerId=" + props.record.partnerId, null).then(resp => {

      const record = resp.rows[0].data;
      console.log(resp)
      setRecord(record)

    })


  }, [props.record.id]);





  const deleteConfirm = () => {
    setDelete(true);
  }
  const deleteData = () => {


    const partnerId = userInfo.partnerId || "zrider";
    deleteRecord("/objects/" + partnerId + "_driverconnect/" + props.record.id + "?partnerId=" + partnerId, null).then(resp => {
      props.refresh();
    })
  }



  return (
    <AnimatePresence>

      <motion.div initial={{ left: 0, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ type: "spring", stiffness: 100 }}

        exit={{ x: 0, opacity: 0 }}>



        <div className="text-xs" style={{
          display: 'block',
          width: "100%",
          color: "#eee",
          height: (window.innerHeight - 74) + "px",
          padding: 10
        }}>

          {
            isDelete &&
            <SweetAlert style={{ color: "black" }}
              warning
              showCancel
              confirmBtnText="Yes, Proceed!"
              confirmBtnBsStyle="danger"
              title="Data will get deleted permanantly. Are you sure?"
              onConfirm={() => { setDelete(false); deleteData() }}
              onCancel={() => { setDelete(false) }}
              focusCancelBtn
            >

            </SweetAlert>
          }
          <Form>





            <Form.Group style={{ height: "60px" }}>



              <div className="flex flex-row gap-2 justify-center">

                <Button variant="info" onClick={() => props.onSelectRecord(null)}>
                  <span className="flex items-center gap-2" ><FaArrowCircleLeft></FaArrowCircleLeft> Close</span>
                </Button>

                <Button variant="danger" type="button" onClick={() => deleteConfirm()}>

                  <span className="flex items-center gap-2" > <FaTrash></FaTrash> Delete</span>
                </Button>




              </div>

              {/* <Row xs="auto">
        <Col> <Button style={{width:"100%"}} variant="info"  onClick={()=>props.onSelectRecord(null)}>
          <FaArrowLeft></FaArrowLeft> Close
        </Button>
        </Col>
        <Col>
        <Button variant="danger"  type="button"  onClick={()=>deleteConfirm()}>
            <FaTimes></FaTimes> Delete
        </Button>
        </Col>
        <Col>
        <Button  variant="primary" type="button"  onClick={()=>saveData()}>
            <FaCheck></FaCheck> Save
        </Button>
        </Col>
      </Row> */}



            </Form.Group>

           

            




            {record && <>

              <div className='flex gap-2 w-full'>

              <Form.Group className='w-1/2'>
              <Form.Label>Passenger</Form.Label>

              <Form.Control as="text" rows="3">{props.record.passenger} </Form.Control>
            </Form.Group>
            <Form.Group className='w-1/2'>
              <Form.Label>Driver</Form.Label>

              <Form.Control as="text" rows="3">{props.record.to} </Form.Control>
            </Form.Group>

                </div>

              <div className='flex gap-2 w-full'>
              <Form.Group className='w-1/2'>
              <Form.Label>Call Status</Form.Label>

              <Form.Control as="text" rows="3">{props.record.status} </Form.Control>
            </Form.Group>

            <Form.Group className='w-1/2'>
              <Form.Label>Vehicle Type:</Form.Label>
              <Form.Control as="select" value={record.vehicleType} disabled>
                <option value="car">Car</option>
                <option value="auto">Auto</option>
                <option value="suv">Suv</option>
                <option value="truck">Truck</option>
                <option value="minibus">Mini Bus</option>
                <option value="ambulance">Ambulance</option>
                <option value="jcb">JCB</option>
                <option value="other">Other</option>


              </Form.Control>
            </Form.Group>

                </div>
              <div className='flex gap-2 w-full'>
              <Form.Group className='w-1/2'>
              <Form.Label>Operation Status</Form.Label>

              <Form.Control as="text" rows="3">{record.operationStatus} </Form.Control>
            </Form.Group>

            <Form.Group className='w-1/2'>
              <Form.Label>Subscription Status:</Form.Label>
              <Form.Control as="select" value={record.subsStatus} disabled>
                <option value={true}>Active</option>
                <option value={false}>InActive</option>

              </Form.Control>
            </Form.Group>
            </div>

            


            <div className='flex gap-2 w-full'>
            <Form.Group>
              <Form.Label>Licence Number:</Form.Label>
              <Form.Control value={record.licenceNumber} disabled/>


            </Form.Group>
            <Form.Group>
              <Form.Label>Vehicle Number:</Form.Label>
              <Form.Control value={record.vehicleNumber} disabled/>


            </Form.Group>
            </div>

            <Form.Group>

              <div className='flex gap-2'>
                <div>
                  <Form.Label>Vehicle Image</Form.Label>
                  <img className='rounded-lg shadow-lg' style={{ height: "200px", width: "200px", objectFit: "cover" }} src={record.vehicleImageURL}></img>
                </div>
                <div>
                  <Form.Label>Driver Image</Form.Label>
                  <img className='rounded-lg shadow-lg' style={{ height: "200px", width: "200px", objectFit: "cover" }} src={record.driverImageURL}></img>
                </div>
              </div>
            </Form.Group>

            <Form.Group>

            </Form.Group>
            </>}




          </Form>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}