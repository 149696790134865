import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import SimpleMap from './SimpleMap';
import { FaArrowCircleLeft, FaArrowLeft, FaCheck, FaCheckCircle, FaCross, FaTimes, FaTrash, FaTrashAlt } from 'react-icons/fa';
import { userInfo } from './UserInfo';
import { put,deleteRecord } from './Api';
import SweetAlert from 'react-bootstrap-sweetalert';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import LocationInput from './maps/LocationInput';
import { AnimatePresence, motion } from "framer-motion"
import { ShowPassword } from './ShowPassword';


export default function OneDRideAccountForm(props) {
  const [status, setStatus] = useState(props.record.subsStatus==true?"Active":"Inactive");
  const [licenceNumber, setLicenceNumber] = useState(props.record.licenceNumber);
  const [vehicleNumber, setVehicleNumber] = useState(props.record.vehicleNumber);
  const [location, setLocation] = useState({latitude:props.record.latitude,longitude:props.record.longitude});
  const [vehicleType, setVehicleType] = useState(props.record.vehicleType);

  const [updatedLocation, setUpdatedLocation] = useState();

  
  const [note, setNote] = useState("");
  const [isDelete, setDelete] = useState(false);

  const onchangeStatus=(evt)=>{
    setStatus(evt.target.value)
  }

  useEffect(() => {
    // Update the document title using the browser API
    setStatus(props.record.subsStatus==true?"Active":"Inactive")
    setLicenceNumber(props.record.licenceNumber);
    setVehicleNumber(props.record.vehicleNumber)
    setLocation({latitude:props.record.latitude,longitude:props.record.longitude})
  },[props.record.id]);


  const saveData=()=>{

    const record = Object.assign({},props.record);
    record.notes=record.notes||[];
    delete record.username;
    record.notes.unshift({
      oldStatus: record.status,
      subsStatus: status=="Active",
      message: note,
      time: new Date().getTime(),
      updateBy: userInfo.firstName,
      updateByUser: userInfo.username
    })
    record.subsStatus=status=="Active";
    record.licenceNumber=licenceNumber;
    record.vehicleNumber=vehicleNumber;
    record.vehicleType=vehicleType;
    const partnerId=userInfo.partnerId||"zrider";
    let url="/accounts/"+props.record.username+"?partnerId="+partnerId;
    if(updatedLocation){
      url+="&lat="+updatedLocation.latitude+"&lon="+updatedLocation.longitude;
      record.latitude=updatedLocation.latitude;
      record.longitude=updatedLocation.longitude;

    }

    put(url,record).then(resp=>{
      props.refresh();
    })
  }


  const deleteConfirm=()=>{
    setDelete(true);
  }
  const deleteData=()=>{


    const partnerId=userInfo.partnerId||"zrider";
    deleteRecord("/objects/"+partnerId+"_account/"+props.record.id+"?partnerId="+partnerId,null).then(resp=>{
      props.refresh();
    })
  }



  return (
    <AnimatePresence>

    <motion.div  initial={{left:0, opacity: 0 }}
        animate={{ x: 0 , opacity: 1 }}
        transition={{ type: "spring", stiffness: 100 }}

        exit={{ x:0,opacity: 0 }}>



    <div className="text-xs"  style={{ display: 'block', 
                  width: "100%",
                  color:"#eee",
                  height:(window.innerHeight-74)+"px",
                  padding: 10 }}>

      {
        isDelete  && 
        <SweetAlert style={{color:"black"}}
	warning
	showCancel
	confirmBtnText="Yes, Proceed!"
	confirmBtnBsStyle="danger"
	title="Data will get deleted permanantly. Are you sure?"
	onConfirm={()=>{setDelete(false);deleteData()}}
	onCancel={()=>{setDelete(false)}}
	focusCancelBtn
  >
	
  </SweetAlert>
      }
      <Form>
      <Form.Group style={{height:"60px"}}>



<div className="flex flex-row gap-2 justify-center">

<Button   variant="info"  onClick={()=>props.onSelectRecord(null)}>
         <span className="flex items-center gap-2" ><FaArrowCircleLeft></FaArrowCircleLeft> Close</span> 
        </Button>
        <ShowPassword accountId={props.record.id} partnerId={props.record.partnerId}></ShowPassword>

        <Button variant="danger"  type="button"  onClick={()=>deleteConfirm()}>
           
            <span className="flex items-center gap-2" > <FaTrash></FaTrash> Delete</span> 
        </Button>
        <Button  variant="primary" type="button"  onClick={()=>saveData()}>
        <span className="flex items-center gap-2" > <FaCheckCircle></FaCheckCircle> Save</span> 

            
        </Button>

       
  
      </div>
      
      {/* <Row xs="auto">
        <Col> <Button style={{width:"100%"}} variant="info"  onClick={()=>props.onSelectRecord(null)}>
          <FaArrowLeft></FaArrowLeft> Close
        </Button>
        </Col>
        <Col>
        <Button variant="danger"  type="button"  onClick={()=>deleteConfirm()}>
            <FaTimes></FaTimes> Delete
        </Button>
        </Col>
        <Col>
        <Button  variant="primary" type="button"  onClick={()=>saveData()}>
            <FaCheck></FaCheck> Save
        </Button>
        </Col>
      </Row> */}
       
       
        
      </Form.Group>



      <Form.Group>
          <Form.Label>Vehicle Type:</Form.Label>
          <Form.Control as="select" value={vehicleType} onChange={(e)=>setVehicleType(e.target.value)}>
        <option value="car">Car</option>
        <option value="auto">Auto</option>
        <option value="suv">Suv</option>
        <option value="truck">Truck</option>
        <option value="minibus">Mini Bus</option>
        <option value="ambulance">Ambulance</option>
        <option value="jcb">JCB</option>
        <option value="other">Other</option>

        
     </Form.Control>
     </Form.Group>


      <Form.Group>
          <Form.Label>Subscription Status:</Form.Label>
          <Form.Control as="select" value={status} onChange={onchangeStatus}>
        <option value="Inactive">Inactive</option>
        <option value="Active">Active</option>
        
     </Form.Control>
     </Form.Group>
     <Form.Group>
          <Form.Label>Licence Number:</Form.Label>
          <Form.Control  value={licenceNumber} onChange={e=>setLicenceNumber(e.target.value)}/>
        
        
        </Form.Group>
        <Form.Group>
          <Form.Label>Vehicle Number:</Form.Label>
          <Form.Control  value={vehicleNumber} onChange={e=>setVehicleNumber(e.target.value)}/>
        
        
        </Form.Group>

        <Form.Group>
        
<div className='flex gap-2'>
  <div>
  <Form.Label>Vehicle Image</Form.Label>
<img className='rounded-lg shadow-lg' style={{height:"200px",width:"200px",objectFit:"cover"}} src={props.record.vehicleImageURL}></img>
</div>
<div>
  <Form.Label>Driver Image</Form.Label>
<img className='rounded-lg shadow-lg' style={{height:"200px",width:"200px",objectFit:"cover"}}  src={props.record.driverImageURL}></img>
</div>
</div>
</Form.Group>

<Form.Group>

</Form.Group>
        <Form.Group>
          <Form.Label>Note</Form.Label>
         
          <Form.Control as="textarea" rows="3" onChange={e=>setNote(e.target.value)} />
        </Form.Group>
        
        <Form.Group>
          <div className='flex items-center'>
            <div className='flex flex-col flex-1'>          <Form.Label>Location(lat,lng):</Form.Label><Form.Label className='flex-1 text-xs'>{location.latitude?location.latitude+","+location.longitude:""}</Form.Label>
            </div>

          <LocationInput className='w-[40px]' type="latlng" location={{lat:location.latitude,lng:location.longitude}} onChange={(info)=>{
            let infol = {...info.location}
            infol.lat=+infol.lat.toFixed(8);
            infol.lng=+infol.lng.toFixed(8);

            setUpdatedLocation({latitude:infol.lat,longitude:infol.lng});
            setLocation({latitude:infol.lat,longitude:infol.lng})
          }}></LocationInput>
          </div>
          <div>
           {location.latitude && <SimpleMap record={{latitude:location.latitude,longitude:location.longitude,driverImageURL:props.record.driverImageURL}}/> }
          </div>
        </Form.Group>
        
      </Form>
    </div>
    </motion.div>
    </AnimatePresence>
  );
}